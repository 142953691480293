import 'slick-carousel';

jQuery(async function () {
	var carouselConfig = $('.home .slick-carousel').slick({
		dots: false,
		arrows: true,
		infinite: false,
		autoplay: false,
		speed: 300,
		slidesToShow: 4,
		touchThreshold: 1000,
		slidesToScroll: 4,
		lazyLoad: 'ondemand',
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			}/*,
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
			*/
		]
	});

	// Destaques
	$('.home h3').on('click', function(){
		var buttonVal = $(this).data('class');
		$('.home div.destaques').each(function(){
			if (!$(this).hasClass(buttonVal)) {
				$(this).addClass('d-none')
			} else {
				if($(this).hasClass('d-none')){
					$(this).removeClass('d-none')
				}
				$(this).find('.slick-slider').slick('refresh')
			}
		})
	});
});